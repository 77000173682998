<template>
  <div class="mainStyle">
    <div v-if="loader">
      <Loading />
    </div>
    <div>
      <HeaderVue :city="city" :style="$route.name === 'Auth' ? 'display: none;' : ''" @openModal="openModal" />
      <router-view />
      <modal :modalHead="modalHead" width=1101px height="auto" :open="isOpenn" @close="isOpenn = !isOpenn">
        <modal-main-city @cityChanged="cityChanged" :isOpen="isOpenn" :city="city" @close="isOpenn = !isOpenn" />
      </modal>
      <modal :modalHead="modalHead2" width=1101px height="auto" :open="basketOpened" @close="$store.commit('showHide')">
        <modal-basket />
      </modal>
      <modal :modalHead="modalHead3" width=1101px height="auto" :open="favouriteOpened"
        @close="$store.commit('showHide2')">
        <modal-favourites/>
      </modal>
      <modal :modalHead="modalHead4" width=1101px height="auto" :open="filterOpened"
        @close="$store.commit('showHide3')">
        <ModalFilter />
      </modal>
      <modal :modalHead="modalHead5" width=1101px height="auto" :open="myOredersOpened"
        @close="$store.commit('showHide4')">
        <ModalMyOrders />
      </modal>
    </div>
  </div>
</template>
<script>
import HeaderVue from '@/components/layouts/HeaderLayout.vue';
import modal from "@/components/modals/modal.vue";
import ModalMainCity from '@/components/modals/modalMainCity.vue';
import ModalBasket from '@/components/modals/modalBasket.vue';
import ModalFilter from '@/components/modals/modalFilter.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Loading from './components/loading.vue';
import ModalFavourites from './components/modals/modalFavourites.vue';
import ModalMyOrders from './components/modals/modalMyOrders.vue';
export default {
  components: {
    HeaderVue,
    modal,
    ModalMainCity,
    ModalBasket,
    Loading,
    ModalFilter,
    ModalFavourites,
    ModalMyOrders
  },
  data: () => ({
    isOpenn: false,
    modalHead: {
      title: 'Выберите город'
    },
    modalHead2: {
      title: 'Корзина'
    },
    modalHead3: {
      title: 'Избранные'
    },
    modalHead4: {
      title: 'Фильтр'
    },
    modalHead5: {
      title: 'Мои заказы'
    },
  }),
  computed: {
    ...mapState(['basketOpened', 'favouriteOpened','filterOpened','myOredersOpened']),
    ...mapGetters({ city: 'GET_CITY' }),
  },
  async created() {
    await this.FETCH_CITY()
  },
  mounted() {
    // if (this.loader) {
    //   document.body.style.overflow = 'hidden'
    // }
    // else document.body.style.overflow = 'auto'
    setInterval(()=>{this.loader = false}, 2000)
  },
  methods: {
    ...mapActions(['FETCH_CITY','CHECK_BASE_CITY']),
    openModal() {
      this.isOpenn = !this.isOpenn
    },
    cityChanged(){
      this.CHECK_BASE_CITY({ city: this.city })
    }

  },
  watch: {
    '$store.state.loader': function () {
      // if (this.loader) {
      //   document.html.style.overflow = 'hidden'
      // }
      // else document.html.style.overflow = 'auto'
    },
    city(){
      this.CHECK_BASE_CITY({ city: this.city })
    },
  }


}
</script>
