<template>
    <transition name="fade">
        <div class="vue-modal" v-show="open">
            <transition name="drop-in">
                <div class="vue-modal-inner" v-show="open">
                    <div class="vue-modal-content">
                        <div class="vue-modal-content-btnClose">
                            <div class="close">
                                <button type="button">
                                    <!-- <span></span> -->
                                </button>
                                <span>
                                    <p class="f-16">{{ modalHead.title }}</p>
                                </span>
                                <button type="button" @click="close">
                                    <span><svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711C10.0976 9.31658 10.0976 8.68342 9.70711 8.29289L8.29289 9.70711ZM0.292893 8.29289C-0.0976311 8.68342 -0.0976311 9.31658 0.292893 9.70711C0.683417 10.0976 1.31658 10.0976 1.70711 9.70711L0.292893 8.29289ZM9.70711 1.70711C10.0976 1.31658 10.0976 0.683417 9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893L9.70711 1.70711ZM0.292893 1.70711L4.29289 5.70711L5.70711 4.29289L1.70711 0.292893L0.292893 1.70711ZM4.29289 5.70711L8.29289 9.70711L9.70711 8.29289L5.70711 4.29289L4.29289 5.70711ZM1.70711 9.70711L5.70711 5.70711L4.29289 4.29289L0.292893 8.29289L1.70711 9.70711ZM5.70711 5.70711L9.70711 1.70711L8.29289 0.292893L4.29289 4.29289L5.70711 5.70711Z"
                                                fill="black" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <slot />
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';

export default {
    // props: {
    //     open: {
    //         type: Boolean,
    //         required: true
    //     },
    //     modalHead: {
    //         type: Array
    //     }
    // },
    props: ["modalHead", "open"],
    setup(_, { emit }) {
        const close = () => {
            emit('close')
        };
        const handleKeyup = (event) => {
            if (event.keyCode === 27) {
                close()
            }
        }

        onMounted(() => document.addEventListener("keyup", handleKeyup));
        onUnmounted(() => document.removeEventListener("keyup", handleKeyup));
        return { close };
    },
    watch: {
        open: function () {
            if (this.open) {
                document.body.style.overflow = 'hidden'
            }
            else document.body.style.overflow = 'auto'
        },
    }
}
</script>

<style lang="scss" scoped>
*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.close {
    top: 20px;
    left: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 0 0 5% 0;

    span {
        .f-16 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #000000;
        }
    }

    button {
        border: none;
        min-width: 32px;
        background: transparent;

        span {
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            display: flex;
            align-items: center;
            background: #EBECEE;
            backdrop-filter: blur(20px);
            border-radius: 36px !important;
            padding: 11px;

            img {
                width: 15px;
                height: 15px;
            }
        }
    }


}

.vue-modal {
    position: fixed;
    display: flex;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 998 !important;
    align-content: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    &-inner {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        // width: clamp(1100px, 40%,500px);
        // margin: 10px auto;

        @media (max-width: 576px) {
            // margin: auto;

        }

        filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.13));
        // z-index: 10000000 !important;
    }

    &-content {
        touch-action: none;
        -ms-touch-action: none;
        -webkit-overflow-scrolling: touch;

        @media (max-width: 576px) {
            width: 100vw;
            max-width: 100%;
            height: 100%;
            padding: 0 20px 20px 20px;
            border-radius: 20px 20px 0px 0px;
            position: relative;
            overflow-y: auto;

            &-btnClose {
                border-radius: 20px 20px 0px 0px;
                position: fixed;
                left: 0;
                right: 0;
                padding: 20px 16px 0 16px;
                background: #F5F6F6;
                z-index: 1;
                opacity: 0.98;
            }
        }

        height: fit-content;
        max-width: 70%;
        position: relative;

        background-clip: padding-box;
        // border-radius: 15px;
        padding: 0;
        background: #F5F6F6;


        &-button {}
    }
}

//animation --->
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    // opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
    transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
    // opacity: 0;
    transform: translateY(100vh);
}

@media (max-width: 576px) {
    .vue-modal {
        &-inner {
            // width: clamp(100%, 40%, 500px);
        }
    }
}
</style>