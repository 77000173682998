<template>
    <div v-if="sliderData" class="swp">
        <div class="swp__content">
            <div class="swp__items">
                <swiper :breakpoints="breakpoints" :observer="true" :observeParents="true" :navigation="navigation1"
                    :effect="'fade'" :speed="400" :pagination="{ clickable: true }" class="swp__items-swiper">
                    <swiper-slide class="swp__items-slide " v-for="item in sliderData" :key="item">
                        <a  class="swp__items-slide-content">
                           <div class="container">
                            <div v-if="item.image" class="swp__items-slide-content-image">
                                <img :src="cdn + item.image" alt="" />
                            </div>
                            <div v-if="item.name" class="swp__items-slide-content-info">
                                <h1 class="f-24"  v-html="item.name"></h1>
                            </div>
                           </div>
                        </a>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import SwiperCore, { Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed } from '@vue/runtime-core';
import { mapGetters } from 'vuex';
SwiperCore.use([Pagination, EffectFade]);
export default {
    props: ['sliderData'],
    components: {
        Swiper,
        SwiperSlide
    },
    data: () => ({
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20
            },
        },
        navigation1: {
            nextEl: '.swpbtnnxt1',
            prevEl: '.swpbtnprv1',
        },
    }),
    computed:{
        ...mapGetters({cdn: 'GET_CDN'})
    }
}
</script>

<style  lang="scss">
// $minWidth: 320;

// // Ширина полотна (макета)
// $maxWidth: 1920;

// // Ширина ограничивающего контейнера (0 = нет ограничения)
// $maxWidthContainer: 1340;

// // Общий отступ у контейнера
// // (30 = по 15px слева и справа, 0 = нет отступа)
// $containerPadding: 30;

// // Ширина срабатывания первого брейкпоинта
// $containerWidth: $maxWidthContainer + $containerPadding;

// // Брейк-поинты
// $pc: $containerWidth; // ПК, ноутбуки, некоторые планшеты в горизонтальном положении
// $laptop: 1366.98;
// $tablet: 991.98; // Планшеты, некоторые телефоны в горизонтальном положении
// $mobile: 767.98; // Телефоны L
// $mobileSmall: 479.98; // Телефоны S

// .banner {
//     padding: 27px 0 120px 0;

//     @media (max-width: $tablet + px) {
//         padding: 23.5px 0 90px 0;
//     }

//     @media (max-width: $mobileSmall + px) {
//         padding: 20px 0 60px 0;
//     }

//     &__swiper {
//         height: 460px;

        

        
//     }

//     &__content {
//         @media (min-width: $mobile + px) {
//             display: flex;
//             justify-content: space-between;
//         }
//     }

//     &__info {
//         box-sizing: content-box;
//         flex: 0 1 622px;
//         padding: 72px 46px;

//         @media (max-width: $tablet + px) {
//             flex: 0 1 550px;
//             padding: 54px 35px;
//         }

//         @media (max-width: $mobile + px) {
//             padding: 35px 35px 0;
//         }

//         @media (max-width: $mobileSmall + px) {
//             padding: 20px 20px 0;
//             margin: 0 0 33px 0;
//         }
//     }

//     &__title {
//         color: #ffffff;
//         font-weight: 600;
//         font-size: 42px;
//         line-height: 121.42%;
//         margin: 0 0 22px;

//         @media (max-width: $tablet + px) {
//             font-size: 35px;
//             margin: 0 0 19px;
//         }

//         @media (max-width: $mobileSmall + px) {
//             font-size: 26px;
//             margin: 0 0 16px;
//         }
//     }

//     &__text {
//         color: #ffffff;
//         font-weight: 400;
//         font-size: 16px;
//         line-height: 118.75%;
//         margin: 0 0 27px;

//         @media (max-width: $tablet + px) {
//             font-size: 15px;
//             margin: 0 0 26px;
//         }

//         @media (max-width: $mobileSmall + px) {
//             font-size: 14px;
//             margin: 0 0 25px;
//         }
//     }

//     &__btn {
//         display: inline-flex;
//         align-items: center;
//         justify-content: center;
//         padding: 0 24px;
//         background: #ffffff !important;
//         color: #6c4e99 !important;
//     }

//     &__image {
//         @media (min-width: $mobile + px) {
//             position: absolute;
//             right: 14px;
//             bottom: 10px;
//         }

//         @media (max-width: $pc + px) {
//             top: 170px;

//             img {
//                 width: 660px;
//                 height: 220px;
//                 object-fit: contain;
//             }
//         }

//         @media (max-width: $tablet + px) {
//             top: 160px;

//             img {
//                 width: 520px;
//                 height: 180px;
//             }
//         }

//         @media (max-width: $mobile + px) {
//             padding: 0 10px 10px;

//             img {
//                 width: 430px;
//                 height: 140px;
//             }
//         }

//         @media (max-width: $mobileSmall + px) {
//             position: relative;
//             padding-bottom: 43.2%;
//             top: 0;

//             img {
//                 position: absolute;
//                 width: 100%;
//                 height: 100%;
//                 top: 0;
//                 left: 0;
//                 object-fit: contain;
//             }
//         }
//     }
// }
</style>