<template>
    <div class="filter">
        <div class="filter__content">
            <!-- @onFocusOut="onFocusOut" -->
            <div v-if="selectCategory === false" class="filter__content-search">
                <searchComponent @onFocus="onFocus" />
            </div>
            <div class="filter__items searchFixed" v-if="search">
                <div v-if="filterData.search.results.length" class="filter__items-titleSearch tle">
                    <div class="filter__items-titleSearch-title tle__title">
                        <span class="f-14">Результаты поиска</span>
                    </div>
                    <div class="filter__items-titleSearch-list">
                        <button @click.stop="toPage(item, 1)" class="filter__items-titleSearch-list-text"
                            v-for="(item, idx) in filterData.search.results" :key="'s1' + idx">
                            <span class="f-14" v-html="item.title"></span>
                            <span><svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div v-if="filterData.search.categories.length" class="filter__items-titleSearch tle">
                    <div class="filter__items-titleSearch-title tle__title">
                        <span class="f-14">Категории</span>
                    </div>
                    <div class="filter__items-titleSearch-list">
                        <button @click.stop="toPage(item, 2)" class="filter__items-titleSearch-list-text"
                            v-for="(item, idx) in filterData.search.categories" :key="'s1' + idx">
                            <span class="f-14" v-html="item.title"></span>
                            <span><svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="filter__items-noSearch" v-else><span><svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M38 38L30.8074 30.8074M30.8074 30.8074C33.8612 27.7537 35.75 23.5349 35.75 18.875C35.75 9.55519 28.1948 2 18.875 2C9.55519 2 2 9.55519 2 18.875C2 28.1948 9.55519 35.75 18.875 35.75C23.5349 35.75 27.7537 33.8612 30.8074 30.8074Z"
                                stroke="#9BA1A8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <p class="f-14">Ничего не найдено</p>
                </div>
            </div>
            <div v-else-if="selectCategory">
                <div v-if="selectSubCategory" class="catalog__items">
                    <ul class="catalog__items-links">
                        <li v-for="(item, index) in links" :key="index">
                            <a @click="fromSubcategoryToPage(item)">
                                <p><img :src="item.icon" alt="" />{{ item.name }}</p>
                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </a>
                        </li>
                        <li v-for="(item, index) in subCategoryList.categories" :key="index">
                            <a @click="fromSubcategoryToPage(item)">
                                <p><img :src="cdn + item.image" alt="" />{{ item.title }}</p>
                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <div v-else class="catalog__items">
                    <ul class="catalog__items-links">
                        <li v-for="(item, index) in categoryList.category" :key="index">
                            <a @click="toSubcategory(item)">
                                <p><img :src="cdn + item.image" alt="" />{{ item.title }}</p>
                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else-if="subCategoryList" class="filter__items">
                <div class="filter__items-sort tle">
                    <div class="filter__items-sort--title tle__title">
                        <span class="f-14">Сортировка</span>
                    </div>
                    <div class="filter__items-sort-list">
                        <div class="form_radio_btn" v-for="(item, index) in radios" :key="index">
                            <input :id="item.id" :checked="$route.query.sort === item.slug" type="radio" name="radio"
                                :value="item" @change="sortBy(item)" v-model="storage">
                            <label class="f-12" :for="item.id">{{ item.text }}</label>
                        </div>
                    </div>
                </div>
                <div class="filter__items-category tle">
                    <div class="filter__items-category-title tle__title">
                        <span class="f-14">Категория</span>
                    </div>
                    <div class="filter__items-category-button">
                        <div class="filter__items-category-button-img">
                            <img src="@/assets/images/png/delete/phone.png" alt="">
                        </div>
                        <div @click="changeCategory" class="filter__items-category-button-text">
                            <span class="f-14" v-if="filterData.category">{{ filterData.category.title ?
                                filterData.category.title : 'Все' }}</span>
                            <span class="f-12" v-if="filterData.subcategory">{{ filterData.subcategory.title ?
                                filterData.subcategory.title : '' }}</span>
                        </div>
                    </div>
                </div>
                <div class="filter__items-average tle">
                    <div class="filter__items-average-title tle__title">
                        <span class="f-14">Цена</span>
                    </div>
                    <div class="filter__items-average-button">
                        <div class="filter__items-average-button-input">
                            <div class="filter__items-average-button-input-left">
                                <label>От</label><input :disabled="true" type="text" v-model="priceArea.minPrice">
                            </div>
                            <div class="filter__items-average-button-input-right">
                                <label>До</label><input :disabled="true" type="text" v-model="priceArea.maxPrice">
                            </div>
                        </div>
                        <div class="filter__items-average-button-area">
                            <DoubleRange :min="min" :max="max" :minThreshold="priceArea.minPrice" :clear="clear"
                                :maxThreshold="priceArea.maxPrice" @update:min="value => priceArea.minPrice = value"
                                @update:max="value => priceArea.maxPrice = value" />
                        </div>
                    </div>
                </div>
                <div v-if="brand.length" class="filter__items-brand tle">
                    <div class="filter__items-brand-title tle__title">
                        <span class="f-14">Бренд</span>
                    </div>
                    <div class="filter__items-brand-list">
                        <div class="form_radio_btn" v-for="(item, index) in brand" :key="index">
                            <input :checked="$route.query.brand == item.id" :id="'B' + item.id" type="radio" name="radio2"
                                :value="item" @change="checkRadio(item)" v-model="radioBrand">
                            <label class="f-12" :for="'B' + item.id">{{ item.name }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!search" class="filter__content-btn">
                <div class="fmtop">
                    <div class="fmtop__items">
                        <div class="fmtop__items-tabs">
                            <div class="fmtop__items-tabs-tab">
                                <button @click="clearChanged" class="f-12">
                                    Сбросить
                                </button>
                            </div>
                            <div class="fmtop__items-tabs-tab">
                                <button @click="enterChanged" class="f-12">
                                    Применить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import searchComponent from '../searchComponent.vue'
import DoubleRange from '../DoubleRange.vue';
export default {
    components: { searchComponent, DoubleRange },
    data: () => ({
        radios: [
            {
                id: 1,
                text: 'Новинки',
                slug: 'new',
            },
            {
                id: 2,
                text: 'Высокий рейтинг',
                slug: 'rating',
            },
            {
                id: 3,
                text: 'Сначала дешевые',
                slug: 'cheap',
            },
            {
                id: 4,
                text: 'Сначала дорогие',
                slug: 'expensive',
            }
        ],
        clear: false,
        radioBrand: '',
        brand: [],
        search: false,
        range: [0, 1000],
        priceArea: {
            minPrice: 0,
            maxPrice: 0
        },
        storage: '',
        selectCategory: false,
        selectSubCategory: false,
        links: [{
            name: "Все",
            icon: require('@/assets/images/png/delete/alli.png'),
            route: '/'
        },
        ]
    }),
    mounted() {
        if (this.filterData) {
            if (this.filterData.price) {
                this.priceArea.minPrice = this.filterData.price.min
                this.priceArea.maxPrice = this.filterData.price.max
            }

        }
    },
    computed: {
        ...mapGetters({ products: 'GET_PRODUCTS', modalOpened: 'GET_FILTER_OPENED', filterData: 'GET_FILTER', categoryList: 'GET_CATEGORY', cdn: 'GET_CDN', subCategoryList: 'GET_SUBCATEGORY' }),

        min() {
            if (this.filterData) {
                if (this.filterData.price) {
                    return this.filterData.price.min || 0
                } else {
                    return 0
                }
            } else {
                return 0
            }
        },
        max() {
            if (this.filterData) {
                if (this.filterData.price) {
                    return this.filterData.price.max || 1
                } else {
                    return 1
                }
            } else {
                return 1
            }
        },

    },
    methods: {
        ...mapActions(['FETCH_FILTER', 'FETCH_CATEGORY', 'FETCH_SUBCATEGORY']),
        checkRadio(value) {
            this.$router.push({ query: { sort: this.$route.query.sort, brand: value.id } })
        },
        sortBy(value) {
            this.$router.push({ query: { sort: value.slug, brand: this.$route.query.brand } })
        },
        onFocus() {
            this.search = true
        },
        toPage(item, value) {
            this.$store.commit('showHide3');
            if (value === 1) {
                this.$router.push({
                    name: 'productbySlug',
                    params: { slug: item.slug }
                })
            } else if (value === 2) {
                this.$router.push({
                    name: 'catalogBySlug',
                    params: {
                        slug: item.slug,
                        subCategory: item.slug
                    }
                })
            }

        },
        onFocusOut() {
            this.search = false
        },
        clearChanged() {
            // this.$store.commit('showHide3');
            this.FETCH_FILTER({ category: this.$route.params.slug })
            this.$router.push({ query: {} })
            this.priceArea.minPrice = this.filterData.price.min
            this.priceArea.maxPrice = this.filterData.price.max
            this.clear = true
        },
        enterChanged() {
            this.FETCH_FILTER({
                category: this.$route.params.slug,
                sortBy: this.$route.query.sort,
                brands: this.$route.query.brand,
                SetToFilter: true,
                price_min: this.priceArea.minPrice,
                price_max: this.priceArea.maxPrice
            })
            this.$store.commit('showHide3');
            if (this.$route.name === 'Shop') {
                this.$router.push({ name: 'catalogBySlug', params: { subCategory: 'all', slug: 'all' } })
            }
        },
        changeCategory() {
            this.selectCategory = true
        },
        toSubcategory(value) {
            this.selectSubCategory = true
            this.FETCH_SUBCATEGORY({ subCategory: value.slug })
        },
        fromSubcategoryToPage(value) {
            this.$store.commit('showHide3');
            this.selectCategory = false
            this.selectSubCategory = false
            this.$router.push({ name: 'catalogBySlug', params: { subCategory: value.slug, slug: value.slug }, query: { fromCategory: true } })
        }
    },
    watch: {
        modalOpened() {
            if (this.modalOpened === true) {
                this.FETCH_FILTER({
                    category: this.$route.params.slug, query: {
                        sort: this.$route.query.sort,
                        brand: this.$route.query.brand
                    }
                })
                this.FETCH_CATEGORY()
            } else if (this.modalOpened === false) {
                this.selectCategory = false
                this.selectSubCategory = false
            }
        },
        filterData() {
            this.priceArea.minPrice = this.filterData.price.min
            this.priceArea.maxPrice = this.filterData.price.max
            this.brand = this.filterData.brands
        },
        $route() {
        }
    }
}
</script>

<style scoped lang="scss">
.form_radio_btn {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px;

    &:nth-last-child(-n+1) {
        margin-right: 0;
    }
}

.form_radio_btn input[type=radio] {
    display: none;
}

.form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    padding: 8px 15px;
    border: 1px solid rgba(29, 142, 246, 0.24);
    border-radius: 8px;
    user-select: none;
    color: #1D8EF6;
}

/* Checked */
.form_radio_btn input[type=radio]:checked+label {
    // background: #ffe0a6;
    background: #1D8EF6;
    border: 1px solid #1D8EF6;
    color: #ffffff;
    // border-radius: 8px;

}

/* Hover */
.form_radio_btn label:hover {
    color: #666;
}

/* Disabled */
.form_radio_btn input[type=radio]:disabled+label {
    background: rgba(29, 142, 246, 0.24);
    color: #ffffff;
    border: 1px solid rgba(29, 142, 246, 0.24);
}
</style>