<template>
    <div class="category">
        <div class="category__content container">
            <div class="category__items tt">
                <div class="category__items-title tt__ext">
                    <h1 class="f-14">Все товары</h1>
                </div>
                <ul v-if="categoryList" class="category__items-links">
                    <li v-for="(item, index) in categoryList" :key="index">
                        <router-link :to="{name: 'catalog', params: {subCategory : item.slug}}">

                            <p v-if="item.title">
                                <img :src="cdn + item.image"
                                    alt="" />
                                    {{ item.title }}</p>


                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>

                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ['categoryList'],
    data: () => ({
        links: [
            {
                name: "Смартфоны",
                icon: require('@/assets/images/png/delete/smarticon.png'),
                route: '/'
            },
            {
                name: "Ноутбуки",
                icon: require('@/assets/images/png/delete/laptopicon.png'),
                route: '/'
            },
            {
                name: "Планшеты",
                icon: require('@/assets/images/png/delete/tableticon.png'),
                route: '/'
            },
            {
                name: "Аксессуары",
                icon: require('@/assets/images/png/delete/accessoryicon.png'),
                route: '/'
            },
        ]
    }),
    computed: {
        ...mapGetters({cdn: 'GET_CDN'})
    }
}
</script>

<style>

</style>