<template>
  <div class="basket">
    <div class="basket__content">
      <div class="basket__items">
        <div v-if="cartList.length">
          <!-- <div class="basket__items-filter">
            <button><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4C6 4.55256 5.77724 5.05119 5.41421 5.41421C5.05119 5.77724 4.55256 6 4 6C2.89543 6 2 5.10457 2 4ZM4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8C5.10429 8 6.10566 7.55119 6.82843 6.82843C7.32527 6.33158 7.69266 5.70308 7.87379 5L17 5C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3L7.87398 3C7.42994 1.27477 5.86384 0 4 0ZM12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14C16 14.5526 15.7772 15.0512 15.4142 15.4142C15.0512 15.7772 14.5526 16 14 16C12.8954 16 12 15.1046 12 14ZM14 10C12.1362 10 10.5701 11.2748 10.126 13L1 13C0.447715 13 0 13.4477 0 14C0 14.5523 0.447715 15 1 15L10.126 15C10.5701 16.7252 12.1362 18 14 18C15.1043 18 16.1057 17.5512 16.8284 16.8284C17.5512 16.1057 18 15.1043 18 14C18 11.7909 16.2091 10 14 10Z"
                  fill="#1D8EF6" />
              </svg>
            </button>

            <span>Доставка сегодня, бесплатно</span>
          </div> -->
          <div class="basket__items-list">
            <div v-for="(item, idx) in products" :key="'a' + idx" class="basket__items-list-card">
              <cardsComponent @DELETE_FROM_BASKET="DELETE_FROM_BASKET" :productCard="item" />
            </div>
          </div>
        </div>
        <div v-else class="basket__items-null">
          <div class="basket__items-null-img">
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.5 3.5L5.14955 3.5L6.07123 7.16603L9.52178 20.8909C9.78066 22.2444 10.8849 23.5 12.4745 23.5H29.1433C30.6532 23.5 31.7441 22.3536 32.0577 21.068L34.899 9.41855C35.1224 8.50237 34.975 7.50102 34.4556 6.69439L33.1944 7.50646L34.4556 6.69438C33.9298 5.87791 33.0344 5.3003 31.9846 5.3003L17.7515 5.3003L8.69552 5.3003L8.35815 3.95838L7.77385 1.63427C7.60621 0.967458 7.00668 0.5 6.31912 0.5L1.5 0.500002C0.671572 0.500003 0 1.17158 0 2C0 2.82843 0.671574 3.5 1.5 3.5ZM12.4433 20.2073L9.44975 8.3003L31.9204 8.3003C31.9242 8.30525 31.9286 8.31128 31.9333 8.31854C31.9865 8.4013 32.0222 8.55268 31.9844 8.70768L33.4417 9.06312L31.9844 8.70769L29.1431 20.3571L30.6004 20.7125L29.1431 20.3571C29.125 20.4313 29.0976 20.4765 29.0791 20.5H12.5387C12.5166 20.4719 12.4832 20.4147 12.4671 20.3205C12.4607 20.2825 12.4527 20.2447 12.4433 20.2073ZM19.5 30C19.5 32.2091 17.7091 34 15.5 34C13.2909 34 11.5 32.2091 11.5 30C11.5 27.7909 13.2909 26 15.5 26C17.7091 26 19.5 27.7909 19.5 30ZM23.5 30C23.5 27.7909 25.2909 26 27.5 26C29.7091 26 31.5 27.7909 31.5 30C31.5 32.2091 29.7091 34 27.5 34C25.2909 34 23.5 32.2091 23.5 30Z"
                fill="#9BA1A8" />
            </svg>

          </div>
          <div class="basket__items-null-text">
            <span class="f-14">Корзина пуста</span>
          </div>
        </div>
        <div class="basket__items-delivery container">
          <div class="basket__items-delivery-price">
            <div class="basket__items-delivery-price-count">
              <span class="f-14">Всего к оплате:</span>
              <p class="f-14">{{ formatPrice(totalPrice) }} ₸</p>
            </div>
          </div>
          <div class="basket__items-delivery-button">
            <button :class="cartList.length <= 0 ? 'inactive' : ''" :disabled="cartList.length === 0" @click="toPage"
              class="f-16 btn_blue-big">Продолжить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import cardsComponent from '../cardsComponent.vue'
import axios from 'axios';
export default {
  components: { cardsComponent },
  data: () => ({
    massBasketList: [],
  }),
  created() {
    // this.FETCH_PRODUCTS_BY_ID({id: this.basketData.ids})
    // this.cartList = JSON.parse(localStorage.getItem("cart_products"));
  },
  mounted(){
    this.toBasket()
  },
  computed: {
    ...mapState(['basketOpened']),
    ...mapGetters({ cartList: 'GET_CART_LIST', products: 'GET_PRODUCTS_BY_ID' }),
    basketData() {
      let newCards = {
        ids: [],
        counts: [],
        test: ''
      }
      this.cartList.map(item => {
        newCards.ids.push(item.id)
        newCards.counts.push(item.count)
      })
      return newCards;

    },
    totalPrice() {
      let result = []
      if (this.cartList) {
        if (this.cartList.length) {
          for (let item of this.cartList) {
            result.push(item.price * item.count);
          }
          result = result.reduce(function (sum, el) {
            return sum + el;
          });
          return result || 0;
        } else {
          return 0;
        }
      }
    },
  },
  methods: {
    ...mapActions(['FETCH_PRODUCTS_BY_ID']),
    check_total_sum() {
      if (cartList) {

      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    toPage() {
      this.$store.commit('showHide')
      this.$router.push({ name: 'Delivery' })
    },
    DELETE_FROM_BASKET() {
      this.FETCH_PRODUCTS_BY_ID({ id: this.basketData.ids })
    },
    toBasket() {
      axios
        .post("addtocart", {
          ids: this.basketData.ids.join(','),
          counts: this.basketData.counts.join(',')
        })
        .then((response) => {
          if (response.status === 200) {
            //    localStorage.setItem('defaultDeliveryCity')
            this.$emit('close')
          } else {
            console.log(response)
          }
        })
        .catch((e) => {
          console.log(e);
        })
    }
  },
  watch: {
    cartList() {
      // if (this.cartList && this.cards){
      //     return this.cards.filter((i) => this.cartList.find((d) => d.id ===  i.id))
      // }
    },
    basketOpened() {
      if (this.basketOpened === true) {
        this.FETCH_PRODUCTS_BY_ID({ id: this.basketData.ids })
      }
    },
    GET_CART_LENGTH() {
      // this.cartList = JSON.parse(localStorage.getItem("cart_products"));
    },
  }
}
</script>

<style></style>