<template>
    <div class="seen">
        <div class="seen__content">
            <div class="seen__items tt">
                <div class="container">
                    <div class="seen__items-title tt__ext">
                        <h1 class="f-14">Недавно смотрели</h1>
                    </div>
                </div>
                <swiper :breakpoints="breakpoints" :centeredSlides="false" :modules="modules" :slides-per-view="4"
                    :space-between="8" :navigation="navigation1" class="seen__items-card left">
                    <swiper-slide v-for="(item, index) in homeData" :key="index">
                        <cardsComponentVue :product-card="item" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import cardsComponentVue from '@/components/cardsComponent.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
export default {
    props: ['homeData'],
    components: {
        cardsComponentVue,
        Swiper,
        SwiperSlide,
    },
    data: () => ({
        navigation1: {
                nextEl: '.selectornx1',
                prevEl: '.selectorpr1',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.1,
                    paceBetween: 5
                },
            },
    })
}
</script>

<style>

</style>