<template>
  <div class="favourites">
    <div class="favourites__content">
      <div class="favourites__items">
        <div v-if="cartList">
          <div class="favourites__items-list">
            <div v-for="(item, idx) in products" :key="'a' + idx" class="favourites__items-list-card">
              <cardsComponent :productCard="item" />
            </div>
          </div>
        </div>
        <div v-else class="favourites__items-null">
          <div class="favourites__items-null-img">
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.5 3.5L5.14955 3.5L6.07123 7.16603L9.52178 20.8909C9.78066 22.2444 10.8849 23.5 12.4745 23.5H29.1433C30.6532 23.5 31.7441 22.3536 32.0577 21.068L34.899 9.41855C35.1224 8.50237 34.975 7.50102 34.4556 6.69439L33.1944 7.50646L34.4556 6.69438C33.9298 5.87791 33.0344 5.3003 31.9846 5.3003L17.7515 5.3003L8.69552 5.3003L8.35815 3.95838L7.77385 1.63427C7.60621 0.967458 7.00668 0.5 6.31912 0.5L1.5 0.500002C0.671572 0.500003 0 1.17158 0 2C0 2.82843 0.671574 3.5 1.5 3.5ZM12.4433 20.2073L9.44975 8.3003L31.9204 8.3003C31.9242 8.30525 31.9286 8.31128 31.9333 8.31854C31.9865 8.4013 32.0222 8.55268 31.9844 8.70768L33.4417 9.06312L31.9844 8.70769L29.1431 20.3571L30.6004 20.7125L29.1431 20.3571C29.125 20.4313 29.0976 20.4765 29.0791 20.5H12.5387C12.5166 20.4719 12.4832 20.4147 12.4671 20.3205C12.4607 20.2825 12.4527 20.2447 12.4433 20.2073ZM19.5 30C19.5 32.2091 17.7091 34 15.5 34C13.2909 34 11.5 32.2091 11.5 30C11.5 27.7909 13.2909 26 15.5 26C17.7091 26 19.5 27.7909 19.5 30ZM23.5 30C23.5 27.7909 25.2909 26 27.5 26C29.7091 26 31.5 27.7909 31.5 30C31.5 32.2091 29.7091 34 27.5 34C25.2909 34 23.5 32.2091 23.5 30Z"
                fill="#9BA1A8" />
            </svg>

          </div>
          <div class="favourites__items-null-text">
            <span class="f-14">Корзина пуста</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import cardsComponent from '../cardsComponent.vue'
export default {
  components: { cardsComponent },
  data: () => ({
  }),
  created() {
    
  },
  computed: {
    ...mapState(['favouriteOpened']),
    ...mapGetters({ cartList: 'GET_FAVOURITE_LIST',products: 'GET_PRODUCTS_BY_ID' }),
    favouritesData() {
      let newCards = {
        ids: [],
        counts: [],
        test:''
      }
      this.cartList.map(item => { 
        newCards.ids.push(item.id)
        newCards.counts.push(item.count)
      })
      return newCards;

    },
  },
  methods: {
    ...mapActions(['FETCH_PRODUCTS_BY_ID']),
  },
  watch: {
    favouriteOpened(){
      if(this.favouriteOpened === true){
        this.FETCH_PRODUCTS_BY_ID({id: this.favouritesData.ids})
      }
    }
  }
}
</script>

<style>

</style>