<template>
    <div class="city">
        <div class="city__content">
            <div class="city__items">
                <div class="city__items-search">
                    <searchCityComponent :isOpen="isOpen" />
                </div>
                <ul v-if="city.length" class="city__items-links">
                    <li v-for="(item, index) in city" :key="index">
                        <a @click="setCity(item)">
                            <p>
                                {{ item.name }}</p>
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L5 5L1 9" stroke="#9BA1A8" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </a>
                    </li>
                </ul>
                <div class="noContent" v-else>
                    <span><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M38 38L30.8074 30.8074M30.8074 30.8074C33.8612 27.7537 35.75 23.5349 35.75 18.875C35.75 9.55519 28.1948 2 18.875 2C9.55519 2 2 9.55519 2 18.875C2 28.1948 9.55519 35.75 18.875 35.75C23.5349 35.75 27.7537 33.8612 30.8074 30.8074Z"
                                stroke="#9BA1A8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <p>Ничего не найдено</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import searchCityComponent from '@/components/searchCityComponent'
export default {
    props: ['city', 'isOpen'],
    components: { searchCityComponent },
    data: () => ({

    }),

    methods: {
        ...mapActions(['CHECK_CITY', 'FETCH_CITY']),
        setCity(value) {
            if (this.$route.name === 'Delivery') {
                localStorage.setItem('location', JSON.stringify(value.id))
                console.log('-----------------------1');
                this.$emit('cityChanged')
                this.$emit('close')
            } else {
                localStorage.setItem('location',  JSON.stringify(value.id))
                console.log('-----------------------2');
                this.$emit('close')
                this.$emit('cityChanged')
            }
            // this.CHECK_BASE_CITY({city: value.name})
        }
    },
    watch: {
        isOpen() {
            if (this.isOpen === true) {
                this.FETCH_CITY()
            }
        }
    }
}
</script>

<style></style>